



$theme-colors: (
    "primary": #51796F,
    "info": #00b1eb,
    "danger": #DC3545,
    "warning": #ffbb00,
    "secondary": #6b7886,
    "light": #e3ecef,
    "success": #51796F,
    "dark": #0e2b63,
    "white": #ffffff,
    "amber": #FFBF00
);

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./outlines.scss";

.btn-light{
    background-color: #99a4ba;
}

.text-italic{
    font-style: italic;
}
html{
    scroll-behavior: smooth !important;
}

.inline-btn{
    display: inline-block !important;
}

.font-14{
    font-size: 14px !important;
}
.font-16{
    font-size: 16px !important;
}
.font-18{
    font-size: 18px !important;
}
.font-30{
    font-size: 30px !important;
}
table{
    .btn-primary{
        background-color: #51796F;
    }
    .btn-sm{
        padding: 2px 4px;
        font-size: 12px !important;
    }
}

