.dashboard-menu {
    border-radius: 8px;
    border: 3px solid #51796fa4;

    text-decoration: none;
    &:hover {
        background-color: #51796fa4 !important;
    }
}

.dashboard-link {
    text-decoration: none;
}

.modal-80w {
    width: 80%;
    max-width: none !important;
}

.modal-90w {
    width: 90%;
    max-width: none !important;
}

.modal-95w {
    width: 95%;
    max-width: none !important;
}

.btn-smaller {
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
}

.bg-light-red {
    background-color: #f8555558;
}
.bg-light-green {
    background-color: #16986a35;
}

.trx-card {
    margin-bottom: 6px;
    p {
        margin-bottom: 0px;


    }
}
