.type-card{
    border-radius: 3px;
}

.border-primary{
    border: 2px solid #51796f77;
}

.border-hide{
    border: 2px solid #a7aaa977;

}