.bottom-navigation {
    .navbar {
        overflow: hidden;
        background-color: #333;
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .navbar a {
        float: left;
        display: block;
        color: #f2f2f2;
        text-align: center;
        padding: 14px 26px;
        text-decoration: none;
        font-size: 17px;
        transition: all 0.3s ease-in-out;
    }

    .navbar a:hover {
        background-color: #ddd;
        color: black;
    }

    .navbar a.active {
        background-color: #51796F;
        color: white;
    }

    .navbar .icon {
        display: none;
    }

    // @media screen and (max-width: 600px) {
    //     .navbar a:not(:first-child) {
    //         display: none;
    //     }
    //     .navbar a.icon {
    //         float: right;
    //         display: block;
    //     }
    // }

    // @media screen and (max-width: 600px) {
    //     .navbar.responsive .icon {
    //         position: absolute;
    //         right: 0;
    //         bottom: 0;
    //     }
    //     .navbar.responsive a {
    //         float: none;
    //         display: block;
    //         text-align: left;
    //     }
    // }
}
